.section--hidden {
  opacity: 0;
  transform: translateY(8rem);
}

.btn, .btn:link, .btn:visited {
  color: #fff;
  cursor: pointer;
  background-color: #000;
  border: none;
  border-radius: .8rem;
  padding: 1rem 2rem;
  font-size: 2rem;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.btn:hover, .btn:active {
  color: #000;
  background-color: #fff;
  border-inline: .2rem solid #000;
}

.section {
  width: 100%;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 9.6rem 4.8rem;
}

.header-container {
  max-width: 120rem;
  padding: 4.8rem;
}

.header-container-center, .section-header-light, .section-subheader-light, .section-header-dark, .section-subheader-dark {
  font-family: Cormorant Garamond, serif;
}

.header-container-center {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 4.8rem;
  display: flex;
}

.section-header-light {
  font-size: 3.2rem;
  line-height: 1.2;
}

.section-subheader-light {
  letter-spacing: .3rem;
  font-size: 2.4rem;
}

.section-header-dark {
  color: #fff;
  font-size: 3.2rem;
  line-height: 1.2;
}

.section-subheader-dark {
  color: #fff;
  letter-spacing: .3rem;
  font-size: 2.4rem;
}

.grid {
  display: grid;
}

.grid-column-1 {
  grid-column: 1fr;
  justify-content: center;
  align-self: center;
  display: grid;
}

.grid-column-2 {
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-self: center;
  display: grid;
}

.grid-column-3 {
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
}

.grid-column-4 {
  grid: 1fr 1fr 1fr 1fr;
  display: grid;
}

.image-hidden {
  position: absolute;
  top: 50%;
  right: 50%;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.lazy-img {
  filter: blur(20px);
}

.btn-gallery {
  margin-bottom: 4.8rem;
}

.hidden--button {
  display: none;
}

:focus {
  outline: none;
  box-shadow: 0 0 0 .8rem #ffd900cc;
}

/*# sourceMappingURL=index.b56a34c3.css.map */
