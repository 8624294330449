/* General Elements */
.section--hidden {
  opacity: 0;
  transform: translateY(8rem);
}

/* General Button design */

.btn,
.btn:link,
.btn:visited {
  display: inline-block;
  background-color: #000;
  color: #fff;
  font-size: 2rem;
  padding: 1rem 2rem;
  border-radius: 0.8rem;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;

  /*for .btn only  */
  border: none;
}

.btn:hover,
.btn:active {
  background-color: #fff;
  border-inline: 0.2rem solid #000;
  color: #000;
}

.section {
  width: 100%;
}

.container {
  padding: 9.6rem 4.8rem;
  margin: 0 auto;
  /* margin-bottom: 12rem; */
  max-width: 120rem;
}

.header-container {
  padding: 4.8rem;
  max-width: 120rem;
}

.header-container-center,
.section-header-light,
.section-subheader-light,
.section-header-dark,
.section-subheader-dark {
  font-family: "Cormorant Garamond", serif;
}

.header-container-center {
  padding: 4.8rem;

  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.section-header-light {
  font-size: 3.2rem;
  line-height: 1.2;
}

.section-subheader-light {
  font-size: 2.4rem;
  letter-spacing: 0.3rem;
}

.section-header-dark {
  color: #fff;
  font-size: 3.2rem;
  line-height: 1.2;
}

.section-subheader-dark {
  color: #fff;
  font-size: 2.4rem;
  letter-spacing: 0.3rem;
}

/* grids */

.grid {
  display: grid;
}

.grid-column-1 {
  display: grid;
  grid-column: 1fr;
  justify-content: center;
  align-self: center;
}

.grid-column-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-self: center;
}

.grid-column-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-column-4 {
  display: grid;
  grid: 1fr 1fr 1fr 1fr;
}

/* Helpers */

.image-hidden {
  position: absolute;
  top: 50%;
  right: 50%;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

/* Lazy Images */
.lazy-img {
  filter: blur(20px);
}

.btn-gallery {
  margin-bottom: 4.8rem;
}

.hidden--button {
  display: none;
}
/* Accesability */

*:focus {
  outline: none;
  /* /* outline: 4px dotted #e67e22; */
  /* outline-offset: 8px; */
  box-shadow: 0 0 0 0.8rem rgba(255, 217, 0, 0.8);
}
